/**
 * Form validation
 */

// export default (() => {
//   const selector = 'needs-validation'

//   window.addEventListener(
//     'load',
//     () => {
//       // Fetch all the forms we want to apply custom Bootstrap validation styles to
//       const forms = document.getElementsByClassName(selector)
//       // Loop over them and prevent submission
//       /* eslint-disable no-unused-vars */
//       const validation = Array.prototype.filter.call(forms, (form) => {
//         form.addEventListener(
//           'submit',
//           (e) => {
//             if (form.checkValidity() === false) {
//               e.preventDefault()
//               e.stopPropagation()
//             }
//             form.classList.add('was-validated')
//           },
//           false
//         )
//       })
//       /* eslint-enable no-unused-vars */
//     },
//     false
//   )
// })()

export default (() => {
  const selector = 'needs-validation'

  window.addEventListener(
    'load',
    () => {
      const forms = document.getElementsByClassName(selector)

      Array.prototype.filter.call(forms, (form) => {
        form.addEventListener(
          'submit',
          async (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (form.checkValidity()) {
              const formData = {
                fullName: document.getElementById('fn').value,
                phone: document.getElementById('phone').value,
                email: document.getElementById('email').value,
                message: document.getElementById('message').value,
              }

              try {
                const submitButton = document.getElementById('submit-button')
                submitButton.disabled = true
                submitButton.innerHTML =
                  '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...'

                const response = await fetch(
                  'https://nucleu.io/api/send-email',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                  }
                )

                if (response.ok) {
                  const contactForm = document.getElementById('contact-form')
                  contactForm.style.display = 'none'

                  const successMessage =
                    document.getElementById('success-message')
                  successMessage.style.display = 'block'
                } else {
                  alert('Error sending message. Please try again.')
                }
              } catch (error) {
                console.error('Error:', error)
                alert('Error sending message. Please try again.')
              } finally {
                const submitButton = document.getElementById('submit-button')
                submitButton.disabled = false
                submitButton.innerHTML = 'Submit'
              }
              // setTimeout(() => {
              //   const contactForm = document.getElementById('contact-form')
              //   contactForm.style.display = 'none'

              //   const successMessage =
              //     document.getElementById('success-message')
              //   successMessage.style.display = 'block'
              // }, 3000)
            }

            form.classList.add('was-validated')
          },
          false
        )
      })
    },
    false
  )
})()
